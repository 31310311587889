<template>
  <div class="box">
    <!-- 标题栏 -->
    <div class="one">
      <van-nav-bar>
        <template #left>
          <img src="@/assets/img/icon_qingjia@2x.png" />
          <span style="margin-left: 0.63rem; font-weight: 700">请假审批</span>
        </template>
        <template #right>
          <van-button
            round
            type="info"
            color="#ececff"
            @click="Gocommit"
            size="small"
          >
            <img src="@/assets/img/add.png" class="add" />
            <span style="color: #666">代学生请假</span>
          </van-button>
        </template>
      </van-nav-bar> 
    </div>
    <div>
      <van-tabs type="card" @click="getName">
        <van-tab title="待审批">
          <div v-for="item in this.leavelist" :key="item.id">
            <!-- 请假列表 -->
            <div class="tones two" @click="getItemid(item.id)">
              <h3 class="lefthead">
                <span class="write">{{ item.type_name | getfirst }}</span>
                <span style="margin-left: 0.31rem">{{ item.type_name }}</span>
              </h3>
              <h1 class="alltime">
                <p>
                  开始时间：<span class="time">{{ item.time_begin }}</span>
                </p>
                <p>
                  结束时间：<span class="time">{{ item.time_end }}</span>
                </p>
                <p>
                  审批人：<span class="time">{{ item.teacher_name }}</span>
                </p>
                <p>
                  发起时间：<span class="time">{{ item.create_time }}</span>
                </p>
                <!-- 判断显示哪张状态图 -->
                <p class="status">
                  <img src="@/assets/img/Group 427319555@2x.png" />
                </p>
                <p class="jiantou">
                  <img src="@/assets/img/jiantou.png" />
                </p>
              </h1>
            </div>
          </div>
        </van-tab>
        <van-tab title="已同意">
          <div v-for="item in this.leavelist" :key="item.id">
            <div class="tones two" @click="getItemid(item.id)">
              <h3 class="lefthead">
                <span class="write">{{ item.type_name | getfirst }}</span>
                <span style="margin-left: 0.31rem">{{ item.type_name }}</span>
              </h3>
              <h1 class="alltime">
                <p>
                  开始时间：<span class="time">{{ item.time_begin }}</span>
                </p>
                <p>
                  结束时间：<span class="time">{{ item.time_end }}</span>
                </p>
                <p>
                  审批人：<span class="time">{{ item.teacher_name }}</span>
                </p>
                <p>
                  发起时间：<span class="time">{{ item.create_time }}</span>
                </p>
                <!-- 判断显示哪张状态图 -->
                <p class="status">
                  <img src="@/assets/img/Group 427319554@2x.png" />
                </p>
                <p class="jiantou">
                  <img src="@/assets/img/jiantou.png" />
                </p>
              </h1>
            </div>
          </div>
        </van-tab>
        <van-tab title="已驳回">
          <div v-for="item in this.leavelist" :key="item.id">
            <div class="tones two" @click="getItemid(item.id)">
              <h3 class="lefthead">
                <span class="write">{{ item.type_name | getfirst }}</span>
                <span style="margin-left: 0.31rem">{{ item.type_name }}</span>
              </h3>
              <h1 class="alltime">
                <p>
                  开始时间：<span class="time">{{ item.time_begin }}</span>
                </p>
                <p>
                  结束时间：<span class="time">{{ item.time_end }}</span>
                </p>
                <p>
                  审批人`：<span class="time">{{ item.teacher_name }}</span>
                </p>
                <p>
                  发起时间：<span class="time">{{ item.create_time }}</span>
                </p>
                <!-- 判断显示哪张状态图 -->
                <p class="status">
                  <img src="@/assets/img/Group 427319553@2x.png" />
                </p>
                <p class="jiantou">
                  <img src="@/assets/img/jiantou.png" />
                </p>
              </h1>
            </div>
          </div>
        </van-tab>
        <van-tab title="已超时">
          <div v-for="item in this.leavelist" :key="item.id">
            <div class="tones two" @click="getItemid(item.id)">
              <h3 class="lefthead">
                <span class="write">{{ item.type_name | getfirst }}</span>
                <span style="margin-left: 0.31rem">{{ item.type_name }}</span>
              </h3>
              <h1 class="alltime">
                <p>
                  开始时间：<span class="time">{{ item.time_begin }}</span>
                </p>
                <p>
                  结束时间：<span class="time">{{ item.time_end }}</span>
                </p>
                <p>
                  审批人：<span class="time">{{ item.teacher_name }}</span>
                </p>
                <p>
                  发起时间：<span class="time">{{ item.create_time }}</span>
                </p>
                <!-- 判断显示哪张状态图 -->
                <p class="status">
                  <span><i> 已撤销 </i></span>
                </p>
                <p class="jiantou">
                  <img src="@/assets/img/jiantou.png" />
                </p>
              </h1>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>
<script>
import { Getleavers1, Cancelleaver } from "@/api/leavelist";
import { Dialog } from "vant";
export default {
  data() {
    return {
      leavelist: [],
      write: "",
      needlist: [],
      list: [],
      list1: [],
      list2: [],
      list3: [],
      name:'teameasure'
    };
  },
  filters: {
    getfirst: (str) => {
      return str.slice(0, 1);
    },
  },
  mounted() {
    this.GetleaversList(0);
  },
  created() {
  },
  methods: {
    //确认是否撤销
    handleCancel() {
      Dialog.confirm({
        title: "提示",
        message: `等待老师审批中 确定撤销该请假？`,
      })
        .then(async () => {
          Cancelleaver("mobile/leave/cancel", { id: this.id });
          await this.GetleaversList();
        })
        .catch(() => {
          // on cancel
        });
    },
    //获取请假列表
    async GetleaversList(status) {
      await Getleavers1("mobile/leave/index", {
        status: status,
        $limit: 5,
        $page: 3,
      }).then((data) => {
        this.leavelist = data.data.rows;
        this.id = data.data.rows.id;
        // console.log("请假列表", this.leavelist);
      });
    },
    //获取当前行的id并且进入到请假详情
    getItemid(id) {
      console.log("当前数据的id", id);
      this.$router.push({ path: "/leavedetail", query: { id:id,name:this.name } });
    },
    //跳转到代学生请假
    Gocommit() {
      this.$router.push("/teasub");
    },
    //获取到此刻点击的tab序号=status
    getName(status) {
      console.log("title", status);
      this.GetleaversList(status);
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 0 0.91rem;
  background: #fafafa;
}
.one {
  height: 4.56rem;
  font-size: 2.12rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.add {
  margin-right: 0.19rem;
  // vertical-align: text-bottom;
  vertical-align: middle;
}
.two {
  flex: 1;
}

.tones {
  height: 14rem;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 1.25rem;
  overflow: hidden;
  margin-bottom: 1.25rem;
  position: relative;
}
.lefthead {
  margin: 1rem 0 0 1.44rem;
  font-size: 0.97rem;
  display: flex;
  align-items: center;
}
.write {
  display: inline-block;
  width: 1.16rem;
  height: 1.16rem;
  background: #5380ff;
  border-radius: 1.16rem;
  font-size: 0.59rem;
  color: #fff;
  font-weight: bolder;
  text-align: center;
}
.time {
  color: #333;
  font-size: 0.81rem;
}
.alltime > p {
  color: #999;
  font-size: 1rem;
  font-weight: 500;
  margin-left: 1.44rem;
}
img {
  width: 1.13rem;
  height: 1.13rem;
}
.status {
  position: absolute;
  top: -0.87rem;
  right: 1.13rem;
}
.status > img {
  width: 3.47rem;
  height: 3.13rem;
  margin-top: 0.63rem;
}
.status > span {
  display: block;
  width: 4.25rem;
  height: 1.38rem;
  border-radius: 0rem 0.75rem 0rem 0.5rem;
  background: rgba(254, 107, 74, 0.15);
  opacity: 1;
  position: absolute;
  top: 0;
  right: -1rem;
  text-align: center;
}
.status > span > i {
  width: 2.06rem;
  height: 0.88rem;
  font-size: 0.69rem;
  font-family: PingFang SC-Regular;
  font-weight: 400;
  color: #fe6b4a;
  line-height: 0.88rem;
  // -webkit-text-fill-color: transparent;
}
.jiantou {
  position: absolute;
  top: 5.72rem;
  right: 1.78rem;
}
.giveup {
  position: absolute;
  top: 9rem;
  right: 1.19rem;
}
/deep/ .van-button--round {
  width: 8rem;
  height: 2rem;
}
/deep/ .van-button--default {
  width: 3.75rem;
  height: 1.75rem;
  border-radius: 0.25rem;
}
/deep/.van-tabs__nav--card {
  box-sizing: border-box;
  height: 2.5rem;
  margin: 0 0.94rem;
  border: 0;
  border-radius: 1.25rem;
  background: #ededf5;
}
/deep/.van-tabs--card > .van-tabs__wrap {
  height: 2.5rem;
  margin-bottom: 1rem;
}
/deep/.van-tabs__nav--card .van-tab.van-tab--active {
  color: #fff;
  background-color: #5380ff;
  border-radius: 1.25rem;
}
/deep/.van-tabs__nav--card .van-tab {
  color: #666;
  border: 0;
}
/deeep/.van-tabs__content {
  margin-top: 1rem;
}
</style>
